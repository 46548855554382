export const PHONE_MASK = "+7(999)999-99-99"
export const DATE_MASK = "99.99.9999"
export const PASSPORT_MASK = "9999 999999"
export const CARD_MASK = "9999 9999 9999 9999"
export const SNILS_MASK = "999-999-999 99"
export const INN_MASK  = "9999 9999 9999"
export const BILLACC_MASK = "9999 9999 9999 9999 9999"
export const BIC_MASK = "999 999 999"


export const EMAIL = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
