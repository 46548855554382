import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { UserApi } from "../../http/api/user.api";
import { ProfileGetRes, ProfileCreateReq, ProfileCreateRes, StorePushTokenReq, StorePushTokenRes, GetProfileFilledRes, EditCardReq, EditCardRes, ProfileGetDocumentRes, EditGenderRes, EditGenderReq, EditFIORes, EditFIOReq,AuthSignDocxReq, AuthSignDocxRes } from "../../types/api/user.api.types";
import { PaymentProfileData, PersonBankData, ProfileBonusesData, ProfileCreateForm, ProfileData, ProfileEditTextFields } from "../../types/entities/user.types";
import { correctFormDate } from "../../utils/correctFormDate";
import { handleTokenRefreshedRequest } from "../../utils/handleThunkAuth";
import { EMAIL } from "../../config/masks";
import { AuthReq, AuthRes } from "../../types/api/user.api.types";
import { formateCard } from "../../utils/formateCard";



type ProfileSliceState = {
    notifictaions_store: {
        sending: boolean,
        err: string
        success: boolean | null
    },
    creating_form: {
        sending: boolean
        err: string,
        disabled: boolean,
        gender: boolean,
        text_fields: ProfileCreateForm
    }, 
    bank_data_form:{
        sending: boolean
        err: string,
        disabled: boolean,
        text_fields: PersonBankData
        
    },
    has_profile: boolean | null
    has_docs: boolean | null
    push_token: string | null
    docs_url: string
    data: ProfileData & PaymentProfileData & ProfileBonusesData,
    form: Omit<ProfileData, "bonus"> & PaymentProfileData,
    profile_got: boolean
    loadings: {
        profile: boolean
        orders: boolean
    },
    edit_form: {
        sending: boolean,
        err: string
        success: boolean | null
    },
    document: {
        loading: boolean,
        url: string,
        err: string
    }
}

const initialState: ProfileSliceState = {
    notifictaions_store: {
        sending: false,
        err: "",
        success: null
    },
    creating_form: {
        sending: false,
        err: "",
        disabled: true,
        gender: true,
        text_fields: {
            passport_numbers: "",
            first_name: "",
            last_name: "",
            subname: "",
            dob: "", // Дата рождения
            pob: "", // Место рождения
            passport_issue_date: "", // Дата выдачи паспорта
            passport_issued_by: "", // Кем выдан паспорт
            email: ""
        }
    },
    bank_data_form:{
        sending: false,
        err: "",
        disabled: true,
        text_fields: {
            address:"",
            INN: "",
            SNILS: "",
            sett_acc: "",
            correspond_acc: "",
            BIC: "",
            //signature: "",
        }
    },
    has_docs: null,
    has_profile: null,
    push_token: null,
    docs_url: "",
    profile_got: false,
    data: {
        first_name: "",
        last_name: "",
        subname: "",
        dob: "",
        image: "",
        bonus: 0,
        gender: true,
        card_number: "",
        minsumm: 0,
        agent_procent: 0
    },
    form: {
        first_name: "",
        last_name: "",
        subname: "",
        dob: "",
        image: "",
        gender: true,
        card_number: ""
    },
    loadings: {
        profile: true,
        orders: false
    },
    edit_form: {
        sending: false,
        err: "",
        success: null
    },
    document: {
        loading: false,
        url: "",
        err: ""
    }
}


export const cardAdd = createAsyncThunk(
    'card/add',
    async (req: EditCardReq, { dispatch }) => {
        const res: AxiosResponse<EditCardRes> = await handleTokenRefreshedRequest(null, UserApi.EditCard, req)
        if (!res.status) {
            throw new Error("Не удалось добавить номер карты!")
        }
        return { ...res.data, ...req }
    }
)

export const changeGender = createAsyncThunk(
    'gender/add',
    async (req: EditGenderReq, {dispatch}) => {
        const res: AxiosResponse<EditGenderRes> = await handleTokenRefreshedRequest(null, UserApi.EditGender, req)
        if(!res.status){
            throw new Error("Не удалось изменить пол!")
        }
        return {...res.data, ...req}
    }
)

export const changeFIO = createAsyncThunk(
    'FIO/add',
    async (req: EditFIOReq, {dispatch}) => {
        const res: AxiosResponse<EditFIORes> = await handleTokenRefreshedRequest(null, UserApi.EditFIO, req)
        if(!res.status){
            throw new Error("Не удалось изменить Ф.И.О")
        }
        return {...res.data, ...req}
    }
)

export const sendSignCode = createAsyncThunk(
    'sign/code',
    async (req: AuthSignDocxReq, { dispatch }) => {
        const res: AxiosResponse<AuthSignDocxRes> = await UserApi.SignDocxCode(req)
        if (!res.data) {
            throw new Error("Ошибка сервера!")
        }
        if (res.status === 401) {
            throw new Error("Неверный код!")
        }

        return res.data
    }
)

export const sendSignPhone = createAsyncThunk(
    'sign/phone',
    async (req: AuthReq, { dispatch }) => {
        const res: AxiosResponse<AuthRes> = await UserApi.LoginPhone(req)
        if (!res.status || !res.data.status) {
            throw new Error("Ошибка сервера!")
        }
        return res.data
        
        // return new Promise<AuthRes>((res, rej) => {
        //     setTimeout(() => {
        //         res({
        //             status: true
        //         })
        //     }, 1000)
        // })
    }
)

export const getUserDocument = createAsyncThunk(
    'user/document/get',
    async (_, { dispatch }) => {
        const res: AxiosResponse<ProfileGetDocumentRes> = await handleTokenRefreshedRequest(null, UserApi.GetDocument)
        return res.data
    }
)
export const getProfile = createAsyncThunk(
    'profile/get',
    async (_, { dispatch }) => {
        const res: AxiosResponse<ProfileGetRes> = await handleTokenRefreshedRequest(null, UserApi.GetProfile)

        return res.data
        // return new Promise<ProfileData>((res, rej) => {
        //     setTimeout(() => {
        //         res({
        //             first_name: "Борис",
        //             last_name: "Борисов",
        //             subname: "Борисович",
        //             dob: "2000-11-11",
        //             image: "/",
        //             bonus: 3,
        //             gender: false
        //         })
        //     }, 1000)
        // })
    }
)

export const createProfile = createAsyncThunk(
    'profile/create',
    async (req: ProfileCreateReq, { dispatch }) => {
        const res: AxiosResponse<ProfileCreateRes> = await handleTokenRefreshedRequest(null, UserApi.Create, req)
        if (!res.status) {
            throw new Error("Не удалось создать профиль!")
        }
        return res.data
        // return new Promise<ProfileCreateRes>((res, rej) => {
        //     setTimeout(() => {
        //         res({
        //             status: true,
        //             url: "https://medmente.ru/uploads/docs/fz_2300_04-08-2023.pdf"
        //         })
        //     }, 1000)
        // })
    }
)


export const getHasProfile = createAsyncThunk(
    'has-profile/get',
    async (logout: any, { dispatch }) => {
        const res: AxiosResponse<GetProfileFilledRes> = await handleTokenRefreshedRequest(logout, UserApi.GetProfileFilled)
        return res.data

        // return {
        //     id: 1,
        //     is_doc_signed: !false,
        //     is_fill_fio: true,
        //     is_phone_confirm: true,
        //     status: true
        // } as GetProfileFilledRes
    }
)

export const ProfileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {
        handleProfileForm: (state, action: PayloadAction<{ key: keyof (ProfileEditTextFields & { card_number: string }), val: string }>) => {
            const key = action.payload.key
            const val = action.payload.val

            if (key === "gender") {
                state.form[key] = Boolean(val)
                return
            }
            state.form[key] = val
        },
        handleCreateProfileGender: (state, action: PayloadAction<boolean>) => {
            state.creating_form.gender = action.payload
        },
        handleEditProfileGender: (state, action: PayloadAction<boolean>) => {
            state.form.gender = action.payload
        },
        handleCreateProfileForm: (state, action: PayloadAction<{ key: keyof typeof initialState.creating_form.text_fields, val: string }>) => {
            if (state.creating_form.err) {
                state.creating_form.err = ""
            }
            const key = action.payload.key
            let val = action.payload.val
            const tempCreatingForm: typeof initialState.creating_form.text_fields = JSON.parse(JSON.stringify(state.creating_form.text_fields))

            if (key === "dob" || "passport_issue_date") {
                val = correctFormDate(val)
            }
            tempCreatingForm[key] = val
            state.creating_form.text_fields = tempCreatingForm
            const datesLengthsAreValid = tempCreatingForm.dob.length === 10 && tempCreatingForm.passport_issue_date.length === 10
            const datesValuesAreaValid = tempCreatingForm.dob.split(".").every(part => /^\d+$/.test(part)) && tempCreatingForm.passport_issue_date.split(".").every(part => /^\d+$/.test(part))
            const pass = tempCreatingForm.passport_numbers.length === 11
            const allFieldsAreNotEmpty = Object.values(tempCreatingForm).every((val) => val.length > 0)
            const emailIsValid = EMAIL.test(tempCreatingForm["email"])

            state.creating_form.disabled = !datesLengthsAreValid || !pass || !allFieldsAreNotEmpty || !emailIsValid || !datesValuesAreaValid
        },

        handleCreateBankForm: (state, action: PayloadAction<{ key: keyof typeof initialState.bank_data_form.text_fields, val: string }>) => {
            if (state.bank_data_form.err) {
                state.bank_data_form.err = ""
            }
            const key = action.payload.key
            let val = action.payload.val
            const tempCreatingForm: typeof initialState.bank_data_form.text_fields = JSON.parse(JSON.stringify(state.bank_data_form.text_fields))

            tempCreatingForm[key] = val
            state.bank_data_form.text_fields = tempCreatingForm
            const INN = tempCreatingForm.INN.length === 14
            const SNILS = tempCreatingForm.SNILS.length === 14
            const BIC = tempCreatingForm.BIC.length === 11
            const correspond_acc = tempCreatingForm.correspond_acc.length === 24
            const sett_acc = tempCreatingForm.sett_acc.length === 24
            const allFieldsAreNotEmpty = Object.values(tempCreatingForm).every((val) => val.length > 0)
            state.bank_data_form.disabled = !INN || !SNILS || !allFieldsAreNotEmpty || !sett_acc || !correspond_acc || !BIC
        },

        setDefaultProfileForm: state => {
            state.form = state.data
        },
        resetCreateProfileForm: state => {
            state.creating_form = initialState.creating_form
        },
        resetStorePushToken: state => {
            state.notifictaions_store = initialState.notifictaions_store
        },
        setHasDocs: (state, action: PayloadAction<boolean>) => {
            state.has_docs = action.payload
        },
        resetProfileData: state => {
            state.data = initialState.data
            state.form = initialState.form
            state.has_profile = initialState.has_profile
            state.push_token = initialState.push_token
        }
    },
    extraReducers: (builder) => {
        //HAS PROFILE
        builder.addCase(getHasProfile.pending, (state, action) => {
        })
        //HAS PROFILE
        builder.addCase(getHasProfile.fulfilled, (state, action) => {
            state.has_profile = action.payload.is_fill_fio
            state.has_docs = action.payload.is_doc_signed
            state.push_token = action.payload.push_token
        })
        builder.addCase(getHasProfile.rejected, (state, action) => {
        })
        //PROFILE
        builder.addCase(getProfile.pending, (state, action) => {
            state.loadings.profile = true
        })
        builder.addCase(getProfile.fulfilled, (state, action) => {
            const receivedData = {
                ...action.payload,
                card_number: formateCard(action.payload.card_number)
            }

            state.data = receivedData
            state.form = receivedData
            state.loadings.profile = false
            state.profile_got = true
        })
        builder.addCase(getProfile.rejected, (state, action) => {

            state.loadings.profile = false
        })

        //PROFILE CREATE
        builder.addCase(createProfile.pending, (state, action) => {
            state.creating_form.sending = true
        })
        builder.addCase(createProfile.fulfilled, (state, action) => {
            state.has_profile = action.payload.status
            state.docs_url = action.payload.url
            state.creating_form.sending = false
        })
        builder.addCase(createProfile.rejected, (state, action) => {
            state.creating_form.err = "Не удалось создать профиль!"
            state.creating_form.sending = false
        })
        //CARD ADD
        builder.addCase(cardAdd.pending, (state, action) => {
            state.edit_form.sending = true
        })
        builder.addCase(cardAdd.fulfilled, (state, action) => {
            state.edit_form.sending = false
            state.data.card_number = formateCard(action.payload.card_number)
            state.form.card_number = formateCard(action.payload.card_number)
        })
        builder.addCase(cardAdd.rejected, (state, action) => {
            state.edit_form.err = "Не удалось изменить номер карты!"
            state.edit_form.sending = false
        })
        //PROFILE DOCUMENT
        builder.addCase(getUserDocument.pending, (state, action) => {
            state.document.loading = true
        })
        builder.addCase(getUserDocument.fulfilled, (state, action) => {
            state.document.url = "https://api.mobile.expresstest.ru" + action.payload.doc
            state.document.loading = false

        })
        builder.addCase(getUserDocument.rejected, (state, action) => {
            state.document.err = "Не удалось загрузить документы"
            state.document.loading = false
        })

    },
})

export const {
    handleProfileForm,
    handleCreateProfileForm,
    handleCreateProfileGender,
    handleCreateBankForm,
    resetCreateProfileForm,
    setHasDocs,
    handleEditProfileGender,
    setDefaultProfileForm,
    resetStorePushToken,
    resetProfileData
} = ProfileSlice.actions


export const profileReducer = ProfileSlice.reducer