import React, { FC , useState, useRef} from 'react'
import styles from './orderItem.module.scss'
import { HasId } from '../../types/common.types'
import useTheme from '../../hooks/useAppColor'
import { normalizeComment } from '../../utils/normalizeComment'

type OrderItemProps = {
    rightTopText: string,
    leftBottomText: string,
    rightBottomText: string
    codeText?: string
    left?: string
    summ?:string
    payed?: string 
} & HasId


export const OrderItem: FC<OrderItemProps> = ({ rightBottomText, rightTopText, leftBottomText, id, codeText, left, summ, payed}) => {
    const getTheme = useTheme()
    const paragraphRef = useRef(null)
    const [isShow, setIsShow] = useState(false)


    function handleParagraph(){
        if(paragraphRef.current){
            setIsShow(!isShow)
        }
    }

    return (
        <div className={`f-column gap-10 ${styles.orderItem} border-bottom`}>
            
            <div className="f-row-betw">
                <div className="d-f al-center gap-20">
                    <p className={`fz-s c-lg ${getTheme("c-dark", "c-lg")}`}>Оплачено: <span>{payed}</span></p>  
                    <p className={`fz-s c-lg ${getTheme("c-dark", "c-lg")}`}>Выдано: <span>{left}</span></p>
                </div>
                <p className={`fz-m ${getTheme("c-dark", "c-lg")}  fw-6`}>Доступно: {rightTopText}</p>
            </div>
            <div className="f-col-betw gap-20">
                <p className={`fz-s c-lg ${isShow ? styles.show : ''}`} ref={paragraphRef} onClick={handleParagraph}>{"Комментарий: " + normalizeComment(leftBottomText, isShow)}</p>
            </div>
            <p className='fz-s c-lg'>{rightBottomText}</p>
        </div>
    )
}
