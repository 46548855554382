import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { AxiosResponse } from "axios"
import { CategoriesApi } from "../../http/api/categories.api"
import { CategoriesGetReq, CategoriesGetRes } from "../../types/api/categories.api.types"
import { HasNextPart, HasPart } from "../../types/common.types"
import { AnalysisApi } from "../../types/entities/analysis.types"
import { CategoryApi } from "../../types/entities/categories.types"
import { handleTokenRefreshedRequest } from "../../utils/handleThunkAuth"

type CategoriesSliceState = {
    loadings: {
        categories: boolean
        pagination: boolean,
    }
    categories: CategoryApi[]
    analisys: AnalysisApi[]
} & HasNextPart & HasPart

const initialState: CategoriesSliceState = {
    loadings: {
        categories: true,
        pagination: false
    },
    categories: [],
    analisys: [],
    can_next: false,
    part: 0
}

export const getCategories = createAsyncThunk(
    'all/categories/get',
    async (req: CategoriesGetReq, { dispatch }) => {
        const res: AxiosResponse<CategoriesGetRes> = await handleTokenRefreshedRequest(null, CategoriesApi.GetByTitle, req)
        return res.data
        // return new Promise<CategoriesGetRes>((res, rej) => {
        //     setTimeout(() => {
        //         res({
        //             status: true,
        //             can_next: true,
        //             analisis: [
        //                 {
        //                     id: 4,
        //                     prepare: [],
        //                     tags: [],
        //                     templates: [],
        //                     name: "Анализ",
        //                     cat: 23,
        //                     code: "sdas",
        //                     cost: 900,
        //                     info: "Информация",
        //                     maxdur: 23,
        //                     mindur: 2,
                            
        //                 }
        //             ],
        //             category: [{
        //                 color: "#ffffff",
        //                 id: 3,
        //                 istake: false,
        //                 name: "Биохимические исследования крови",
        //                 take: 128
        //             }]
        //         })
        //     }, 1000)
        // })
    })

export const CategoriesSlice = createSlice({
    name: "categories",
    initialState,
    reducers: {
        resetCategoriesProducts: state => {
            state.analisys = initialState.analisys
            state.categories = initialState.categories
            state.can_next = false
            state.part = 0
        },
        incrementCategoriesProductsPart: state => {
            state.part += 1
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCategories.pending, (state, action) => {
            if (state.part > 1) {
                state.loadings.pagination = true
                return
            }
            state.loadings.categories = true
        })
        builder.addCase(getCategories.fulfilled, (state, action) => {
            state.analisys = [...state.analisys, ...action.payload.analisis]
            state.categories = action.payload.category
            state.loadings.categories = false
            state.can_next = action.payload.can_next
            state.loadings.pagination = false
            if (state.part === 0) {
                state.part = 1
            }
        })
        builder.addCase(getCategories.rejected, (state, action) => {
            state.loadings.categories = false
            state.loadings.pagination = false
            state.can_next = false
        })
    },
})
export const {
    incrementCategoriesProductsPart,
    resetCategoriesProducts,
} = CategoriesSlice.actions

export const categoriesReducer = CategoriesSlice.reducer